import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

const CartSectionHeader = ({ title }) => {
  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      pad="medlarge"
      border={{ side: 'horizontal', size: 'small', color: 'black' }}
      className="cart-header"
    >
      <Heading level={4} margin="none">
        {title}
      </Heading>
    </Box>
  );
};

export default CartSectionHeader;

CartSectionHeader.propTypes = {
  title: PropTypes.string,
};
